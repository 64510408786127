<script>
	import { browser } from '$app/env';
	import { onMount } from 'svelte';
	import { MODULES, MODULES_DICT } from '$lib/js/config/modules/modules_definition';
	import { appStores } from '$lib/stores/appStores';
	import { Translator } from '$lib/js/translations/Translator';

	const T = new Translator($appStores.language, [
		// Page translations
		...MODULES_DICT,
		{ en: 'Home', 'zh-tw': '首頁' },
		{ en: 'Search...', 'zh-tw': '搜尋...' },
	]);

	let NinjaKeys;

	const getIcon = (p) => {
		const style = `
			width: 16px;
			margin: auto;
			padding-right: 24px;
			padding-left: 0px;
			white-space: nowrap;
		`;

		if (p.icon) return `<img src="${p.icon}" style="${style}" />`;
		return `<div style="${style}">${p.emoji}</div>`;
	};

	$: hotkeys = [
		{
			id: 'Home',
			title: T.out('Home'),
			hotkey: 'cmd+h',
			icon: getIcon({ emoji: '🏠' }),
			handler: () => {
				window.location.href = '/modules';
			},
		},
		...MODULES.map((m) => m.pages)
			.flat()
			.filter((p) => {
				if ($appStores.userRoles?.includes('Admin')) return true;
				return p?.roles?.some((r) => $appStores?.userRoles?.includes(r));
			})
			.map((p) => ({
				id: p.path,
				title: p[$appStores.language ?? 'en'] ?? p.en,
				icon: getIcon(p),
				handler: () => {
					window.location.href = p.path;
				},
			})),
	];

	onMount(async () => {
		if (browser) {
			// Dynamically import ninja-keys only on the client side
			const module = await import('ninja-keys');
			NinjaKeys = module.NinjaKeys;

			// Wait for next tick to ensure component is mounted
			setTimeout(() => {
				const ninja = document.querySelector('ninja-keys');
				console.log('ninja', ninja);
				if (ninja) {
					ninja.data = hotkeys;
				}
			}, 0);
		}
	});

	function handleSelected(event) {
		// (Optional) component-wide selected event
		// useful to handle if no action found
		// detail = { search: '...', action: NinjaAction | undefined }
		// so on empty action you can perform API search or etc
		console.log('handleSelected', event.detail);
	}
</script>

{#if browser}
	<ninja-keys
		on:selected={handleSelected}
		placeholder={T.out('Search...')}
		navigationUpHotkey="up,shift+tab,ctrl+p"
		navigationDownHotkey="down,tab,ctrl+n"
		noAutoLoadMdIcons={true}
		hideBreadcrumbs={true}
	/>
{/if}
