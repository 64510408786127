<script>
	import '../app.css';

	import { session } from '$app/stores';
	import { page } from '$app/stores';
	import { supabaseClient } from '$lib/db';
	import { SupaAuthHelper } from '@supabase/auth-helpers-svelte';

	// Navigation component

	import Navigation from '$lib/components/Misc/Navigation/Navigation.svelte';

	// Custom Prompts

	import CustomConfirm from '$lib/components/Modals/Prompts/CustomConfirm.svelte';
	import CustomTextPrompt from '$lib/components/Modals/Prompts/CustomTextPrompt.svelte';
	import CustomNumberPrompt from '$lib/components/Modals/Prompts/CustomNumberPrompt.svelte';
	import CustomSearchPrompt from '$lib/components/Modals/Prompts/CustomSearchPrompt.svelte';
	import CustomFormPrompt from '$lib/components/Modals/Prompts/CustomFormPrompt.svelte';

	// Global Store Driven Elements

	import GlobalTopBanner from '$lib/components/Base/Alerts/GlobalTopBanner.svelte';

	// Global Menu

	import GlobalImagePopout from '$lib/components/Modals/Prompts/GlobalImagePopout.svelte';
	import GlobalVideoPopout from '$lib/components/Modals/Prompts/GlobalVideoPopout.svelte';
	import GlobalSlideMenu from '$lib/components/Misc/GlobalSideMenu/GlobalSlideMenu.svelte';
	import GlobalCommandBar from '$lib/components/Misc/Global/GlobalCommandBar.svelte';

	// Initialize the Sentry SDK

	import * as Sentry from '@sentry/svelte';
	import { BrowserTracing } from '@sentry/tracing';

	Sentry.init({
		dsn: 'https://86ade0fe300a4862a212dd14def00eed@o1399796.ingest.sentry.io/6727676',
		integrations: [new BrowserTracing()],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
</script>

<svelte:head>
	<script
		src="https://cdn.jsdelivr.net/npm/luxon@3.1.0/build/global/luxon.min.js"
		integrity="sha256-Gf7owtNug1Hx/SyBFHX+wOBCo6BH4ucEyTZOD+85yNA="
		crossorigin="anonymous"></script>
</svelte:head>

{#if $page.url.pathname === '/'}
	<div>
		<div class="antialiased">
			<slot />
		</div>
	</div>
{:else}
	<SupaAuthHelper {supabaseClient} {session}>
		<div>
			<Navigation />
			<GlobalTopBanner />
			<div class="container mx-auto antialiased">
				<slot />
			</div>
		</div>
	</SupaAuthHelper>

	<GlobalCommandBar />
	<GlobalSlideMenu />
	<GlobalImagePopout />
	<GlobalVideoPopout />
	<CustomConfirm />
	<CustomTextPrompt />
	<CustomNumberPrompt />
	<CustomSearchPrompt />
	<CustomFormPrompt />
{/if}
